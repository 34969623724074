import { Layout } from 'components/Layout';
import { Seo } from 'components/Seo';
import { GatsbyLink, LoginLink } from 'components/Links';
import { HeroContainer, Section } from 'components/Container';

import type { FC } from 'react';
import type { LocalizedPageProps } from '@sharesight/gatsby-plugin-sharesight-localization';

export const SurveyThanks: FC<LocalizedPageProps> = () => (
  <Layout>
    <Seo />

    <HeroContainer>
      <Section>
        <h1>Thank you</h1>
        <p>
          Your feedback is important to us, so thanks for taking the time to complete our survey.
        </p>

        <br />

        <p>
          Back to <GatsbyLink to="/">Sharesight</GatsbyLink> or{' '}
          <LoginLink>log in to your portfolio</LoginLink>
        </p>
      </Section>
    </HeroContainer>
  </Layout>
);

export default SurveyThanks;
